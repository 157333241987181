.site .stories {
  min-height: 22rem;
  display: grid;
  grid-gap: 0.1rem; }

.site {
  max-width: 40rem;
  border: #3B4252 solid;
  padding: 0.2rem;
  margin: 0 0.1rem 0 0; }

.site .next {
  float: right; }

.site {
  color: #8FBCBB;
  overflow: hidden; }

.site a {
  text-decoration: none;
  color: #ECEFF4; }

.site a:visited {
  color: #EBCB8B; }

.about a {
  color: #8FBCBB; }

.about a:visited {
  color: #B48EAD; }

.about {
  color: #D8DEE9; }

.configuration {
  color: #D8DEE9; }

select {
  background-color: #090a1b;
  border: #4C566A solid 2px;
  color: #D8DEE9;
  margin-left: 10px; }

input {
  background-color: #090a1b;
  border: #4C566A solid 2px;
  color: #D8DEE9;
  margin-left: 10px; }

.site .details {
  font-size: 0.75rem; }

.story .title .domain {
  font-size: 0.5rem;
  color: #81A1C1; }

.mute {
  color: #D08770;
  cursor: pointer; }

.site .index {
  font-size: 0.7rem; }

.app .container {
  margin: 0.5rem;
  display: grid;
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  justify-items: center; }

@media only screen and (max-width: 599px) {
  .app .container {
    grid-template-columns: repeat(1, 1fr); }
  .tr {
    grid-column: 1;
    grid-row: 2; }
  .bl {
    grid-column: 1;
    grid-row: 3; }
  .br {
    grid-column: 1;
    grid-row: 4; }
  .configuration {
    grid-column: 1;
    grid-row: 5; }
  .about {
    grid-column: 1;
    grid-row: 6; }
  .site {
    min-width: 18rem; } }

@media only screen and (min-width: 900px) {
  .app .container {
    grid-template-columns: repeat(2, 1fr); }
  .tr {
    grid-column: 2;
    grid-row: 1; }
  .bl {
    grid-column: 1;
    grid-row: 2; }
  .br {
    grid-column: 2;
    grid-row: 2; }
  .configuration {
    grid-column: 1;
    grid-row: 3; }
  .about {
    grid-column: 2;
    grid-row: 3; }
  .site {
    min-width: 30rem; } }

.tl {
  grid-column: 1;
  grid-row: 1; }

.clear {
  float: clear; }

.site .refresh {
  float: right;
  margin: 0;
  padding: 0 0.2rem;
  cursor: pointer; }

.container .configuration {
  justify-self: left; }

button {
  background-color: #0e1d2a;
  color: #D8DEE9;
  border: #4C566A solid 2px;
  border-radius: 2px;
  margin: 5px; }

button:hover {
  background-color: #1b2950; }

body {
  background-color: #2E3440; }

.loading {
  text-align: center;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
.notfound {
    text-align: center;
    cursor: pointer;
  }
.experimental {
    font-size: 0.5rem;
    vertical-align: top;
    cursor: help;
  }
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

